import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { bodyTextStyles, Heading4 } from "./Typography";
import { text } from "./Colors";

export const StyledPostCard = styled.article`
  display: flex;
  flex-direction: column;
`;

export const ThumbnailLink = styled(Link)`
  margin-bottom: 10px;
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
`;

export const Thumbnail = styled(GatsbyImage)`
  max-height: 260px;
  border-radius: 3px;
`;

export const PostCardDate = styled.span`
  ${bodyTextStyles}
  font-weight: 700;
  font-size: 14px;
  position: relative;
  position: relative;
  padding-left: 80px;
  margin: 0 10px 10px 0;
  :before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 66px;
    left: 2px;
    background-color: ${text};
  }
`;

export const PostCard = ({ post, ...props }) => {
  return (
    <StyledPostCard {...props}>
      <ThumbnailLink to={post.frontmatter.slug}>
        <Thumbnail
          image={getImage(post.frontmatter.featuredImage)}
          alt={post.frontmatter.title}
        />
      </ThumbnailLink>
      <PostCardDate>{post.frontmatter.formattedDate}</PostCardDate>
      <TitleLink to={post.frontmatter.slug}>
        <Heading4>{post.frontmatter.title}</Heading4>
      </TitleLink>
    </StyledPostCard>
  );
};
