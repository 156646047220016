import React, { useState } from "react";
import styled from "styled-components";
import { Section } from "./Section";
import { PostCard } from "./PostCard";
import { link } from "./Colors";

const StyledPostsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const LoadMoreButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: ${link};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 0;
  margin-top: 40px;
  border-top: 1px solid #b3c9db;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    padding: 40px 0;
  }
`;

export const PostsList = ({ posts }) => {
  const [visiblePosts, setVisiblePosts] = useState(posts.slice(0, 6));
  const hasMorePosts = posts.length > visiblePosts.length;

  const showMorePosts = () => {
    setVisiblePosts((visiblePosts) => [
      ...visiblePosts,
      ...posts.slice(visiblePosts.length, visiblePosts.length + 6),
    ]);
  };

  return (
    <Section title={"Raksti"}>
      <StyledPostsList>
        {visiblePosts.map((post) => (
          <PostCard post={post} key={post.frontmatter.slug} />
        ))}
      </StyledPostsList>
      {hasMorePosts && (
        <LoadMoreButton onClick={() => showMorePosts()}>
          Apskatīt vairāk rakstus
        </LoadMoreButton>
      )}
    </Section>
  );
};
