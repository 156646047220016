import styled, { css } from "styled-components";
import { text } from "./Colors";

export const headingStyles = css`
  font-family: "Inria Sans";
  color: ${text};
  font-weight: 700;
  line-height: 1;
`;

export const Heading1 = styled.h1`
  ${headingStyles}
  font-size: 36px;
  @media screen and (min-width: 600px) {
    font-size: 45px;
  }
  @media screen and (min-width: 960px) {
    font-size: 75px;
  }
`;

export const Heading2 = styled.h2`
  ${headingStyles}
  font-size: 35px;
`;

export const Heading3 = styled.h3`
  ${headingStyles}
  font-size: 29px;
`;

export const Heading4 = styled.h4`
  ${headingStyles}
  font-size: 23px;
`;

export const Heading5 = styled.h5`
  ${headingStyles}
  font-size: 18px;
`;

export const bodyTextStyles = css`
  font-family: "PT Sans", sans-serif;
  color: ${text};
  line-height: 1.44;
  font-size: 18px;
`;

export const Body = styled.p`
  ${bodyTextStyles};
`;

export const Subtitle = styled(Body)`
  font-size: 16px;
`;
