import React from "react";
import styled from "styled-components";
import { Heading2 } from "./Typography";

const StyledSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionTitle = styled(Heading2)`
  width: 100%;
  margin-bottom: 40px;
  text-transform: uppercase;
`;

export const Section = ({ title, children }) => {
  return (
    <StyledSection>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </StyledSection>
  );
};
