import styled from "styled-components";
import { PostCard, Thumbnail } from "./PostCard";

export const FeaturedPost = styled(PostCard)`
  margin-bottom: 40px;

  ${Thumbnail} {
    max-height: 400px;
  }
`;
