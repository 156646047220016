import React from "react";
import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import Seo from "../components/SEO";
import { PostsList } from "../components/PostsList";
import { isBefore } from "date-fns";
import { FeaturedPost } from "../components/FeaturedPost";

const Index = ({ data }) => {
  const [featuredPost, ...otherFeaturedPosts] = data.featuredPosts.nodes;
  const posts = [...otherFeaturedPosts, ...data.posts.nodes].sort(
    ({ frontmatter: { date: aDate } }, { frontmatter: { date: bDate } }) => {
      return isBefore(new Date(aDate), new Date(bDate)) ? 0 : 1;
    }
  );

  return (
    <Layout>
      <Seo />
      {featuredPost && <FeaturedPost post={featuredPost} />}
      <PostsList posts={posts} />
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { type: { eq: "post" } }
        frontmatter: { hidden: { ne: true }, featuredPost: { ne: true } }
      }
    ) {
      nodes {
        frontmatter {
          date
          formattedDate: date(formatString: "DD.MM.YYYY.")
          title
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 590
                height: 260
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    featuredPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { type: { eq: "post" } }
        frontmatter: { hidden: { ne: true }, featuredPost: { eq: true } }
      }
    ) {
      nodes {
        frontmatter {
          date
          formattedDate: date(formatString: "DD.MM.YYYY.")
          title
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 1220
                layout: CONSTRAINED
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
